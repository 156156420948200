<template>
  <div>
    <b-button class="mr-4" variant="primary" @click="$emit('print-clicked')">
      Print
    </b-button>
    <b-button variant="primary" @click="$emit('legend-clicked')">
      Legend
    </b-button>
  </div>
</template>

<script>
export default {
  name: "SchoolEmployeeMonthlyAttendanceExportControl",
};
</script>

<style scoped></style>
