<template>
  <b-table-simple :responsive="true" :bordered="bordered">
    <!--  Head  -->
    <b-thead>
      <b-tr>
        <b-th colspan="2">MAIN</b-th>
      </b-tr>
    </b-thead>
    <!-- end of head    -->
    <b-tbody>
      <b-tr>
        <b-td> <strong>W</strong> </b-td>
        <b-td> Working </b-td>
      </b-tr>
      <b-tr>
        <b-td> <strong>AB</strong> </b-td>
        <b-td> Absent </b-td>
      </b-tr>
      <b-tr>
        <b-td> <strong>HO</strong> </b-td>
        <b-td> Holiday </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
export default {
  name: "SchoolWorkingSectionExportLegend",
  props: {
    bordered: {
      required: false,
      default: true,
    },
  },
  data() {
    return {
      fields: [],
      items: [
        {
          id: 1,
        },
      ],
    };
  },
};
</script>

<style scoped></style>
