<template>
  <div class="my-auto mr-4">
    <SchoolEmployeeMonthlyAttendanceExportControl
      @legend-clicked="showLegend"
      @print-clicked="printExport"
    ></SchoolEmployeeMonthlyAttendanceExportControl>

    <b-sidebar
      id="test-sidebar"
      v-model="isSidebarOpen"
      right
      backdrop
      lazy
      sidebar-class="offcanvas"
      header-class="offcanvas-header d-flex mt-5 ml-5 mr-5"
      bg-variant="white"
    >
      <template v-slot:header-close>
        <button
          class="btn btn-sm btn-icon btn-light-secondary btn-hover-danger"
        >
          <i class="ki ki-close icon-xs"></i>
        </button>
      </template>

      <div class="d-flex mx-4 my-5 flex-grow-1">
        <SchoolEmployeeExportLegend
          :leaves="leaves"
          :aways="aways"
        ></SchoolEmployeeExportLegend>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import SchoolEmployeeMonthlyAttendanceExportControl from "@/modules/school/components/management/attendance/SchoolEmployeeMonthlyAttendanceExportControl";
import SchoolEmployeeExportLegend from "@/modules/school/components/management/attendance/utility/SchoolEmployeeExportLegend";
import { mapGetters } from "vuex";

export default {
  name: "TeacherMonthlyExportControl",
  components: {
    SchoolEmployeeExportLegend,
    SchoolEmployeeMonthlyAttendanceExportControl,
  },
  data() {
    return {
      isSidebarOpen: false,
    };
  },
  methods: {
    showLegend() {
      this.isSidebarOpen = true;
    },
    printExport() {
      window.print();
    },
  },
  computed: {
    ...mapGetters({
      leaves: "getSchoolLeaveDetailList",
      aways: "getSchoolAwayDetailList",
    }),
  },
};
</script>

<style scoped></style>
