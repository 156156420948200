<template>
  <div :class="computeClass">
    <SchoolWorkingSectionExportLegend
      class="mr-4"
    ></SchoolWorkingSectionExportLegend>

    <SchoolAwaySectionExportLegend
      class="mr-4"
      :aways="aways"
    ></SchoolAwaySectionExportLegend>

    <SchoolLeaveSectionExportLegend
      class="mr-4"
      :leaves="leaves"
    ></SchoolLeaveSectionExportLegend>

    <SchoolTotalSectionExportLegend></SchoolTotalSectionExportLegend>
  </div>
</template>

<script>
import SchoolLeaveSectionExportLegend from "@/modules/school/components/management/attendance/utility/SchoolLeaveSectionExportLegend";
import SchoolWorkingSectionExportLegend from "@/modules/school/components/management/attendance/utility/SchoolWorkingSectionExportLegend";
import SchoolTotalSectionExportLegend from "@/modules/school/components/management/attendance/utility/SchoolTotalSectionExportLegend";
import SchoolAwaySectionExportLegend from "@/modules/school/components/management/attendance/utility/SchoolAwaySectionExportLegend";
export default {
  name: "SchoolEmployeeExportLegend",
  components: {
    SchoolAwaySectionExportLegend,
    SchoolTotalSectionExportLegend,
    SchoolWorkingSectionExportLegend,
    SchoolLeaveSectionExportLegend,
  },
  props: {
    leaves: {
      type: Array,
      required: true,
    },
    aways: {
      type: Array,
      required: true,
    },
    isRow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initialClass: "d-flex flex-column flex-grow-1",
    };
  },
  computed: {
    computeClass() {
      if (this.isRow) {
        return "d-flex flex-column flex-grow-1 flex-md-row flex-grow-1";
      }

      return this.initialClass;
    },
  },
};
</script>

<style scoped></style>
